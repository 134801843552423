@import url(https://fonts.googleapis.com/css?family=Open+Sans|Chilanka);
@import url(https://fonts.googleapis.com/css?family=Open+Sans|Chilanka);
@import url(https://fonts.googleapis.com/css?family=Shrikhand|Raleway);
@import url(https://fonts.googleapis.com/css?family=Shrikhand|Raleway);
@import url(https://fonts.googleapis.com/css?family=Shrikhand|Raleway);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Lust';
    src: url("/static/media/Lust Slim Display Didone.88f005c8.ttf") format('truetype');
    font-weight: normal;
    font-style: normal;
}

.title {

padding-top: 2.5%;

font-family: Chilanka;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 60px;
text-align: center;

color: #FFFFFF;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

.line1 {
/* Line 1 */

position: absolute;
left: 0%;
right: 0%;
top: 55%;
bottom: 0%;

width: 30%;
border: 1px solid #2E2C2F;
-webkit-transform: rotate(90deg);
        transform: rotate(90deg);

}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: blue orange;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: black;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #2E2C2F;
    border-radius: 20px;
    border: 3px solid black;
  }

.prot{
    
    position: absolute;
    left: 0%;
    right: 50%;
    top: 70%;
    bottom: 0%;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 4rem;
    line-height: 75px;
    text-align: center;
    color: #FFFFFF;
}

.pert{
    
    position: absolute;
    left: 50%;
    right: 00%;
    top: 70%;
    bottom: 0%;

    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 4em;
    line-height: 75px;
    text-align: center;
    color: #FFFFFF;
}

.proi{
    max-width: 9rem;
    position: absolute;
    left: 20%;
    top: 30%;
    text-align: center;

}

.peri{
    max-width: 10rem;
    position: absolute;
    left: 70%;
    top: 30%;
    text-align: center;
}

.proi:hover,.peri:hover {
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
-webkit-transform: scale(1.4);
        transform: scale(1.4);
}

.span2 {
    font-size:125%;
    color: #2E2C2F;
}

.hiddenbutton {
    visibility: hidden;
}

.sender{
    position: absolute;
    left: 00%;
    right: 00%;
    top: 90%;
    bottom: 0%;
}

.sendinput {
    margin-left: 1em;
    margin-right: 5%;
    width: 70%;
    font-size: 1.5em;
}

.sendbt{
    font-size: 1.5em;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .title {
        font-size: 2rem;
    }

    .prot {
        font-size: 1.5em;
    }
    .pert {
        font-size: 1.5em;
    }

    .proi {
        left: 10%
    }
    .peri {
        left: 60%
    }
}
.blogtitle{

padding-top: 1%;


font-family: Chilanka;
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 95px;
text-align: center;

color: #2E2C2F;
}

.blogpost{
background-color: #475B63;
border: 10px solid  rgb(45, 54, 58);
}

.blogttl{
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 95px;

}

.blogdate{
    color: white;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 25px; 
}

.align{
    text-align: center;
}

.blogimage{
    border: 10px solid  #272838; 
    width: 30vw;
}

.aller{
    font-family: "Raleway";
    text-align: right;

}

.blogdetes{
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: transparent;
    color: white;
    font-family: 'Raleway';
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    overflow-wrap: break-word;
    border: none;
    white-space: pre-wrap;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .blogtitle {
        font-size: 2rem;
    }}
.drop{
   background-color: black;
   position: absolute;
   right: 5%;
   color: #2E2C2F;
   border-color: #2E2C2F;
}

.drop:hover, .drop:active, .show>.drop.dropdown-toggle, .show>.drop.dropdown-toggle:focus, .drop:focus{
    background-color: #2E2C2F;
    border-color: #2E2C2F;
    box-shadow: 0 0 0 0.2rem #2E2C2F;
}

.dropcard{
    font-family: Shrikhand;
    color: white;
    border-color: #2E2C2F;
    font-size: 20px;
    background-color: transparent;
}

.dropmenu{
    background-color: black;
    border-color: #2E2C2F;
    text-align: center;
}

.dropcard:hover{
    color: #2E2C2F;
    background-color:transparent;
}

@media only screen and (max-width: 768px) {
    
}
.editsubmit{
    background-color: transparent;
    position: absolute;
    right: 5%;
    top: 5%;
    font-family: raleway;
    color: #2E2C2F;
    border-color: #2E2C2F;
}

.edate{
    font-family: Shrikhand;
    color: white;

    font-size: 1.5em;
    width: 60%;
    background-color: transparent;
    border-color: transparent;
}

.topic{
    font-family: Shrikhand;
    color: #2E2C2F;
    left: 15%;
    font-size: 2em;
    width: 75%;
    background-color: transparent;
    border-color: transparent;

}

.detail{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 85%;
    font-size: 1.5em;
}

.ocurr{
    border-color: white;
    border-width: 1px;
    color: white;
    font-family: Raleway;
    background-color: transparent;
    position: absolute;
    width: 6%;
    top: 20%;
    right: 5%;
}

.ocrlbl{
    color:white;
    position: absolute;
    font-family:raleway;
    top: 15%;
    right: 5%;
}

.radiohead{
    font-family: Raleway;
    font-size: 1.5em;
    color: white;
    position: relative;
    left: 5%;
}

.radioparty{
    font-family: Raleway;
    background-color: #2E2C2F;
    position: relative;
    left: 5%;
    color: white;
}

.radioparty:checked{
    font-family: Raleway;
    background-color: white;
    position: relative;
    left: 15%;
    color: blue;
    display: none;
}

.archive{
    position: relative;
    left: 5%;
}

.complete{
    position: relative;
    left: 7.5%;
    color: red;
    border-color: Red;
}
.crmwidget{
    width: 50%;
    max-width: 1vw;
}

.crmwidgetspace{
    padding-bottom: 1%;
    padding-right: 1%;
    padding-left: 1%;
    background-color: #6e5794;
    /* background-color: #0b0b0b; */
}

.plan{
    background-color: transparent;
    font-family: Raleway;
    border-color: transparent;
    color: white;
    width: 85%;
    font-size: 1.75em;
}

.plansubmit{
    background-color: transparent;
    position: absolute;
    right: 5%;
    top: 15%;
    font-family: raleway;
    color: white;
}

.date{
    font-family: raleway;
    color: white;
    background-color: transparent;
    border-color: transparent;
}

.note{
    font-family: raleway;
    color: white;
}

.newnote{
    background-color: transparent;
    border-color: white;
    color: white;
    font-family: raleway;
}

.printbtn{
    background-color: transparent;
    font-family: raleway;
    color: #2E2C2F;
    border-color: #2E2C2F;
    border-width: 1px;
    position: absolute;
    top: 25%;
    right: 5%;
}

.bt1{
    background-color: transparent;
    border-color: #2E2C2F;
    font-family: raleway;
    color: #2E2C2F;
    border-width: 1px;
}

.bt2{
    background-color: transparent;
    border-color: white;
    font-family: raleway;
    color: white;
    border-width: 1px;
}

.circle{
    display:block;
    width:6em;
    height:6em;
    border-radius:3em;
    border-color: #2E2C2F;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:#000;
}

.online{
    display:block;
    width:3em;
    height:3em;
    border-radius:3em;
    border-color: black;
    font-size:10px;
    color: black;
    text-align:center;
    background: greenyellow;
}

.offline{
    display:block;
    width:3em;
    height:3em;
    border-radius:3em;
    border-color: black;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:red;
}

.view{
    display:block;
    width:6em;
    height:6em;
    border-radius:3em;
    border-color: #2E2C2F;
    font-size:10px;
    color:#fff;
    text-align:center;
    background:#2E2C2F;
}

.fullnote:hover td{
    background-color: #2E2C2F;
    color: white;
    border-color:white;
}

.fullnote:hover .bt2{
    background-color: white;
    color: #00ff00;
    border-color:white;
}

.fullnote:hover .bt1{
    background-color: white;
    color: #8542F2;
    border-color:white;
}
